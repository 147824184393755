import React from 'react'
import Loadable from 'react-loadable'
import I18n from '../I18n'

function Loading() {
  return <div>Loading...</div>
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading
})

const Profile = Loadable({
  loader: () => import('./views/Profile/Profile'),
  loading: Loading
})

const Users = Loadable({
  loader: () => import('./views/Custom/User/UserList'),
  loading: Loading
})

const MasterBook = Loadable({
  loader: () => import('./views/Custom/Book/BookMasterList'),
  loading: Loading
})

const UserBook = Loadable({
  loader: () => import('./views/Custom/Book/UserBookList'),
  loading: Loading
})

const Coupon = Loadable({
  loader: () => import('./views/Custom/Coupon/Coupon.js'),
  loading: Loading
})

const Variant = Loadable({
  loader: () => import('./views/Custom/Variant/Variant.js'),
  loading: Loading
})
// const BookPreview = Loadable({
//   loader: () => import('./views/Book/BookPreview'),
//   loading: Loading
// })

// Shopify
// const Products = Loadable({
//   loader: () => import('./views/Shopify/Products'),
//   loading: Loading
// })

// const AddProduct = Loadable({
//   loader: () => import('./views/ShopifyAd/Product'),
//   loading: Loading
// })

// const WebGL = Loadable({
//   loader: () => import('./views/Preview/WebGL'),
//   loading: Loading
// })

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/dashboard', name: I18n.t('dashboard'), component: Dashboard },
  { path: '/profile', name: I18n.t('tab_profile'), component: Profile },
  { path: '/users', name: I18n.t('tab_users'), component: Users },
  { path: '/master_book', name: I18n.t('tab_master_book'), component: MasterBook },
  { path: '/user_book', name: I18n.t('tab_user_book'), component: UserBook },
  { path: '/coupon', name: I18n.t('tab_coupon'), component: Coupon },
  { path: '/variant', name: I18n.t('tab_variant'), component: Variant },
  // For test
  // { path: '/book/preview', name: I18n.t('tab_book_preview'), component: BookPreview },
  // { path: '/shopify/products', name: I18n.t('tab_test1'), component: Products },
  // { path: '/shopify/add_product', name: I18n.t('tab_test2'), component: AddProduct },
  // { path: '/3d_preview', name: I18n.t('tab_preview'), component: WebGL }
]

export default routes

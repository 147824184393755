import apisauce from 'apisauce'
import ApiConfig from '../Config/ApiConfig'
const autoBind = require('react-autobind')
class API {
  constructor (loginToken, baseURL = ApiConfig.baseURL) {
    this.api = apisauce.create({
      // base URL is read from the "constructor"
      baseURL,
      // here are some default headers
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        // 'Authorization': localStorage.getItem('loginToken') ? 'jwt ' + localStorage.getItem('loginToken') : ''
      },
      // 15 second timeout...
      timeout: 15000
    })
    // const naviMonitor = (response) => // console.log('hey!  listen! ', response)
    // this.api.addMonitor(naviMonitor)

    this.loginToken = loginToken

    autoBind(this)
  }

  authenticated (loginToken) {
    // console.log('loginToken', loginToken)
    this.loginToken = loginToken
    // localStorage.setItem('loginToken', loginToken)
    this.api.setHeader('Authorization', 'jwt ' + loginToken)
  }

  handleUnauthorizedRequest (rawResponse) {
    if (rawResponse.status === 401) {
      this.authenticated('')
      return true
    }
    return false
  }

  preprocessResponse (rawResponse) {
    if (rawResponse && rawResponse.problem && rawResponse.problem === 'TIMEOUT_ERROR') {
      return { message: '要求はタイムアウトしました' }
    }

    if (!rawResponse || rawResponse.problem) {
      return { message: '応答なしまたはネットワークエラー' }
    }

    if (this.handleUnauthorizedRequest(rawResponse)) {
      return { message: 'Unauthorized' }
    }

    const result = rawResponse.data || {}
    result.success = result.status === 'success'
    return result
  }

  login (params) {
    return this.api.post('admin/login', params).then(data => {
      if (data && data.problem && data.problem === 'TIMEOUT_ERROR') {
        return { message: '要求はタイムアウトしました' }
      }

      if (!data || data.problem) {
        return { message: '応答なしまたはネットワークエラー' }
      }

      const result = data.data
      result.success = result.status === 'success'
      if (result.success) {
        this.authenticated(result.data.access_token)
      }
      return result
    })
  }

  logout () {
    return this.api.get('logout').then(result => {
      this.authenticated('')
      return result
    })
  }

  register (params) {
    return this.api.post('register', params).then(data => {
      if (data && data.problem && data.problem === 'TIMEOUT_ERROR') {
        return { message: '要求はタイムアウトしました' }
      }

      if (!data || data.problem) {
        return { message: '応答なしまたはネットワークエラー' }
      }

      const result = data.data
      if (!result) {
        return { message: 'No response' }
      }
      result.success = result.status === 'success'
      if (result.success) {
        this.authenticated(result.data.access_token)
      }
      return result
    })
  }

  forgotPassword (params) {
    return this.api.post('forgot_password', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  updateUsername (params) {
    return this.api.post('update_username', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  changePassword (params) {
    return this.api.post('change_password', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  userUpdate (params) {
    return this.api.post('user_update', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  user (params) {
    return this.api.get('me').then(data => {
      return this.preprocessResponse(data)
    })
  }

  upload (params) {
    return this.api.post('upload', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  resetPassword (params) {
    return this.api.post('reset_password', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  async userList (params) {
    const data = await this.api.get('/admin/user', params)
    return this.preprocessResponse(data)
  }

  async cmsModelList (params) {
    const data = await this.api.get(`/admin/${params.model}`, params)
    return this.preprocessResponse(data)
  }

  async cmsModelUpdate (params) {
    const data = await this.api.put(`/admin/${params.model}/${params.id}`, { data: params.data })
    return this.preprocessResponse(data)
  }

  async cmsModelAdd (params) {
    const data = await this.api.post(`/admin/${params.model}`, { data: params.data })
    return this.preprocessResponse(data)
  }

  async cmsModelDelete (params) {
    const data = await this.api.delete(`/admin/${params.model}`, params)
    return this.preprocessResponse(data)
  }

  async shopifyAddProduct (params) {
    const data = await this.api.post('/shopify/add_product', params)
    return this.preprocessResponse(data)
  }

  async getBook (params) {
    const data = await this.api.post(`/admin/book_master/${params.id}`, params)
    return this.preprocessResponse(data)
  }

  async couponList (params) {
    const data = await this.api.get(`/admin/coupon/list`, params)
    return this.preprocessResponse(data)
  }

  async reloadMasterCache (params) {
    const data = await this.api.get(`/admin/reload_master_cache`, params)
    return this.preprocessResponse(data)
  }

  async userBookPreview (params) {
    const data = await this.api.post(`/user_book/preview`, params)
    return this.preprocessResponse(data)
  }
}

const api = new API()

export default api

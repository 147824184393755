import I18n from '../I18n'

export default {
  items: [
    {
      name: I18n.t('dashboard'),
      url: '/dashboard',
      icon: 'icon-home'
    },
    {
      name: I18n.t('tab_profile'),
      url: '/profile',
      icon: 'fa fa-gear'
    },
    {
      name: I18n.t('tab_users'),
      url: '/users',
      icon: 'fa fa-user'
    },
    {
      name: I18n.t('tab_master_book'),
      url: '/master_book',
      icon: 'fa fa-book'
    },
    {
      name: I18n.t('tab_variant'),
      url: '/variant',
      icon: 'fa fa-ticket'
    },
    {
      name: I18n.t('tab_user_book'),
      url: '/user_book',
      icon: 'fa fa-book'
    },
    {
      name: I18n.t('tab_coupon'),
      url: '/coupon',
      icon: 'fa fa-ticket'
    }
    // {
    //   name: I18n.t('tab_test1'),
    //   url: '/shopify/products',
    //   icon: 'icon-pie-chart'
    // },
    // {
    //   name: I18n.t('tab_test2'),
    //   url: '/shopify/add_product',
    //   icon: 'icon-pie-chart'
    // },
    // {
    //   name: I18n.t('tab_test3'),
    //   url: '/3d_preview',
    //   icon: 'icon-pie-chart'
    // }
  ]
}

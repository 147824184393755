
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  bookRequest: ['params'],
  bookSuccess: ['data'],
  bookFailure: ['error'],
  bookPreviewRequest: ['params'],
  bookPreviewSuccess: ['bookPreviewData'],
  bookPreviewFailure: ['bookPreviewError'],
  clearData: null
})

export const BookTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: [],
  bookPreviewData: [],
  objects: {},
  error: null,
  bookPreviewError: null,
  fetching: false,
  bookPreviewFetching: false
})

/* ------------- Reducers ------------- */

export const bookRequest = state => state.merge({ fetching: true, error: null })
export const bookSuccess = (state, { data }) => state.merge({ fetching: false, error: null, data })
export const bookFailure = (state, { error }) => state.merge({ fetching: false, error })
export const bookPreviewRequest = state => state.merge({ bookPreviewFetching: true, bookPreviewError: null, bookPreviewData: null })
export const bookPreviewSuccess = (state, { bookPreviewData }) => state.merge({ bookPreviewFetching: false, bookPreviewError: null, bookPreviewData })
export const bookPreviewFailure = (state, { bookPreviewError }) => state.merge({ bookPreviewFetching: false, bookPreviewError })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BOOK_REQUEST]: bookRequest,
  [Types.BOOK_SUCCESS]: bookSuccess,
  [Types.BOOK_FAILURE]: bookFailure,
  [Types.BOOK_PREVIEW_REQUEST]: bookPreviewRequest,
  [Types.BOOK_PREVIEW_SUCCESS]: bookPreviewSuccess,
  [Types.BOOK_PREVIEW_FAILURE]: bookPreviewFailure
})

import { call, put } from 'redux-saga/effects'
import ResetPasswordActions from '../Redux/ResetPasswordRedux'
export function * resetPassword (api, {params}) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(ResetPasswordActions.resetPasswordSuccess(res.data))
    } else {
      yield put(ResetPasswordActions.resetPasswordFailure(res.message))
    }
  } catch (error) {
    yield put(ResetPasswordActions.resetPasswordFailure(error.message))
  }
}

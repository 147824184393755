import React, { Component } from 'react'
import {translate} from 'react-i18next'
import { Button, Container, Row, Col, CardGroup, Card, CardBody } from 'reactstrap'
var autoBind = require('react-autobind')
class ConfirmForgotPassword extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: this.props.match.params.email || ''
    }
    autoBind(this)
  }
  login (e) {
    this.props.history.push('/login')
    if (e) { e.preventDefault() }
  }
  render () {
    return (
      <div className='app flex-row align-items-center'>
        <Container>
          <Row className='justify-content-center'>
            <Col md='6'>
              <CardGroup>
                <Card className='p-4'>
                  <CardBody>
                    <h1>パスワードリセット</h1>
                    <div>{this.state.email} に確認のメールを送信しました。</div>
                    <Row className='p-3' style={{marginTop: '20px'}}>
                      <Button color='success' block onClick={this.login.bind(this)}>{this.props.t('OK')}</Button>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default translate('translations')(ConfirmForgotPassword)

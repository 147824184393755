import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { Container } from 'reactstrap'

const propTypes = {
  children: PropTypes.node
}

const defaultProps = {}

class DefaultFooter extends Component {
  render () {
    return (
      <Container>
        <div className='copyright text-center'>
          <div className='container p-3 text-muted'>
            {this.props.t('footer_msg')}
          </div>
        </div>
      </Container>
    )
  }
}

DefaultFooter.propTypes = propTypes
DefaultFooter.defaultProps = defaultProps

export default translate('translations')(DefaultFooter)

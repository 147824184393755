'use-scrict'

import Loadable from 'react-loadable'
import Loading from './Loading'
import Login from '../Containers/views/Pages/Login/Login'
import DefaultLayout from '../Containers/containers/DefaultLayout/DefaultLayout'
import ForgotPassword from '../Containers/views/Pages/ForgotPassword/ForgotPassword'
import ConfirmForgotPassword from '../Containers/views/Pages/ConfirmForgotPassword/ConfirmForgotPassword'
import ResetPassword from '../Containers/views/Pages/ResetPassword/ResetPassword'
import ConfirmResetPassword from '../Containers/views/Pages/ConfirmResetPassword/ConfirmResetPassword'

const AsyncNextPage = Loadable({
  loader: () => import('../Routes/NextPage'),
  loading: Loading
})

const routes = [
  {
    title: 'Login',
    path: '/login',
    component: Login,
    exact: true
  },
  {
    title: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    title: 'Confirm Forgot',
    path: '/confirm-forgot-password',
    component: ConfirmForgotPassword
  },
  {
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword
  },
  {
    title: 'Confirm Reset Password',
    path: '/confirm-reset-password',
    component: ConfirmResetPassword
  },
  {
    title: 'NextPage',
    path: '/next-page',
    component: AsyncNextPage,
    exact: true
  },
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout
  }
]

export default routes


import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  couponListRequest: ['params'],
  couponListSuccess: ['list'],
  couponListFailure: ['listError'],
  clearData: null
})

export const CouponTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: [],
  objects: {},
  listError: null,
  listFetching: false
})

/* ------------- Reducers ------------- */

export const couponListRequest = state => state.merge({ listFetching: true, listError: null })
export const couponListSuccess = (state, { list }) => state.merge({ listFetching: false, listError: null, list })
export const couponListFailure = (state, { listError }) => state.merge({ listFetching: false, listError })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.COUPON_LIST_REQUEST]: couponListRequest,
  [Types.COUPON_LIST_SUCCESS]: couponListSuccess,
  [Types.COUPON_LIST_FAILURE]: couponListFailure
})


import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  masterCacheRequest: ['params'],
  masterCacheSuccess: ['data'],
  masterCacheFailure: ['error'],
  clearData: null
})

export const MasterCacheTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: [],
  objects: {},
  error: null,
  fetching: false
})

/* ------------- Reducers ------------- */

export const masterCacheRequest = state => state.merge({ fetching: true, error: null })
export const masterCacheSuccess = (state, { data }) => state.merge({ fetching: false, error: null, data })
export const masterCacheFailure = (state, { error }) => state.merge({ fetching: false, error})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.MASTER_CACHE_REQUEST]: masterCacheRequest,
  [Types.MASTER_CACHE_SUCCESS]: masterCacheSuccess,
  [Types.MASTER_CACHE_FAILURE]: masterCacheFailure
})

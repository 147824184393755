export default {
  ROLE: {
    USER: 'user',
    OPR: 'operator',
    ADMIN: 'admin'
  },
  SHORT_MESSAGE_CHAR_NUM: 15,
  REQUIRED_PASSWORD_LENGTH: 5,
  REQUIRED_WATCHWORD_LENGTH: 5,
  REQUIRED_USERNAME_LENGTH: 2,
  MAXIMUM_USERNAME_LENGTH: 50
}
